<template>
    <div :class="classes" class="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        isEligible() {
            return this.getUserEligibility(this.data) === 'eligible';
        },
        classes() {
            return {
                'bg-green-200': this.isEligible,
                'text-green-700': this.isEligible,
                'bg-red-200': !this.isEligible,
                'text-red-700': !this.isEligible
            };
        },
        text() {
            const eligibility = this.getUserEligibility(this.data);
            return eligibility === 'eligible' ? 'Elegível' : 'Inelegível';
        }
    },
    methods: {
        getUserEligibility(user) {
            if (!user?.kivid && user?.hasOldPassports) {
                return 'ineligible';
            }

            if (!user?.kivid) {
                return 'no_passport';
            }

            if (user?.kivid?.is_active && new Date(user?.kivid?.valid_until) > new Date()) {
                return 'eligible';
            }

            return 'ineligible';
        }
    }
};
</script>

<style></style>
