import TableTag from '@/components/tables/TableTag.vue';
const Columns = [
    {
        label: 'ID',
        name: 'id',
        orderable: true
    },
    {
        label: 'Name',
        name: 'name',
        orderable: true
    },
    {
        label: 'Email',
        name: 'email',
        orderable: true
    },
    {
        label: 'Telefone',
        name: 'phone',
        orderable: true
    },
    {
        label: 'Tipo',
        name: 'tipos',
        orderable: false
    },
    {
        label: 'Elegibilidade',
        columnName: 'user_plans.is_active',
        orderable: false,
        component: TableTag
    }
];

export { Columns };
