<template>
    <div>
        <TopPage title="Usuários" :options="topOptions" complement="" />

        <DefaultTable @item-selected="itemSelected" title="Usuários" endpoint="dashboard/users" :options="tableOptions" :columns="columns" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TopPage from '@/components/TopPage.vue';
import DefaultTable from '@/components/tables/DefaultTable.vue';
import { Columns } from './columns';

export default {
    name: 'Operational',
    components: {
        TopPage,
        DefaultTable
    },
    data() {
        return {
            columns: Columns,
            tableOptions: {
                cards: false,
                type: 'default',
                topFilters: true,
                pagination: false,
                placeholderSearch: 'Ex.: Clivale'
            },
            topOptions: {
                datefilter: false
            }
        };
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    methods: {
        itemSelected(item) {
            this.setUser(item);
            this.$router.push({ name: 'operational.user', params: { id: item.id } });
        },
        ...mapActions('operational', ['setUser'])
    }
};
</script>

<style></style>
